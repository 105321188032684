.footer {
    width: 100%;
    height: 325px;
    background-color: rgb(0 81 55);
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: 32.2% 67.8%;
    grid-template-rows: 100px 175px 50px;
    grid-template-areas:
    "title contacts"
    "social-media contacts";
}

.footer__social-media {
    border-right: 1px #fff solid;
    margin: 80px 0 50px;
    padding: 40px 0 0 125px;
    margin: 0;
    display: flex;
    gap: 20px;
    grid-area: social-media;
}

.footer__social-media-icon {
    width: 100%;
    transition: opacity 0.1s ease;
}

.footer__social-media-icon:hover {
    opacity: .8;
}

.footer__social-media-link {
    width: 40px;
    height: 40px;
}

.footer__title {
    font-size: 28px;
    padding: 0 0 0 80px;
    margin: 0;
    grid-area: title;
    align-self: flex-end;
}

.footer__other-contacts {
    display: flex;
    flex-direction: column;
    margin: 95px 0 0 70px;
    grid-area: contacts;
}

.footer__other-contacts-title {
    margin: 0;
    padding-left: 50px;
    font-weight: normal;
    font-size: 19px;
}

.footer__contacts-list {
    list-style-type: none;
    padding: 0;
    margin: 10px 0 0;
}

.footer__contacts-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.footer__contacts-item-icon {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: 80% 80%;
}

.footer__contacts-item-icon_address {
    background-image: url(../../images/footer-address.svg);
}

.footer__contacts-item-icon_phone {
    background-image: url(../../images/footer-phone.svg);
}

.footer__contacts-item-icon_email {
    background-image: url(../../images/footer-mail.svg);
}

.footer__contacts-item-text {
    font-size: 16px;
}

@media screen and (max-width: 1200px) {
    .footer {
        grid-template-columns: 22.2% 77.8%;
    }

    .footer__title {
        font-size: 16px;
        padding: 0 0 0 40px;
    }

    .footer__social-media {
        padding: 38px 0 0 57px;
        gap: 35px;
    }
}

@media screen and (max-width: 981px) {
    .footer__social-media {
        padding: 38px 0 0 40px;
    }
}

@media screen and (max-width: 863px) {
    .footer {
        height: 249px;
        grid-template-columns: 21.2% 78.8%;
        grid-template-rows: 50px 159px 40px;
    }

    .footer__title {
        font-size: 14px;
        padding: 0 0 0 24px;
    }

    .footer__other-contacts-title {
        font-size: 12px;
    }

    .footer__contacts-item-text {
        font-size: 12px;
    }

    .footer__other-contacts {
        margin: 51px 0 0 70px;
    }
}

@media screen and (max-width: 839px) {
    .footer__social-media {
        padding: 38px 0 0 24px;
    }
}

@media screen and (max-width: 769px) {
    .footer {
        height: 170px;
        grid-template-columns: 21.2% 78.8%;
        grid-template-rows: 50px 100px 20px;
    }

    .footer__title {
        font-size: 12px;
        padding: 0 0 0 17px;
    }

    .footer__social-media {
        padding: 27px 0 0 18px;
        gap: 19px;
    }

    .footer__social-media-icon {
        width: 75%;
    }

    .footer__other-contacts-title {
        font-size: 9px;
        padding-left: 31px;
    }

    .footer__contacts-item-text {
        font-size: 9px;
    }

    .footer__contacts-item-icon {
        width: 20px;
        height: 20px;
    }

    .footer__other-contacts {
        margin: 44px 0 0 48px;
    }

    .footer__contacts {
        margin: 41px 0 28px;
    }

    .footer__contacts-list {
        margin: 0;
    }
}

@media screen and (max-width: 641px) {
    .footer {
        grid-template-columns: 22.2% 77.8%;
    }
}


@media screen and (max-width: 599px) {
    .footer {
        height: 170px;
        grid-template-columns: 21.2% 78.8%;
        grid-template-rows: 50px 100px 20px;
    }

    .footer__title {
        font-size: 12px;
        padding: 0 0 0 17px;
    }

    .footer__social-media {
        padding: 27px 0 0 18px;
        gap: 19px;
    }

    .footer__social-media-icon {
        width: 75%;
    }

    .footer__other-contacts-title {
        font-size: 9px;
        padding-left: 31px;
    }

    .footer__contacts-item-text {
        font-size: 9px;
    }

    .footer__contacts-item-icon {
        width: 20px;
        height: 20px;
    }

    .footer__other-contacts {
        margin: 44px 0 0 48px;
    }

    .footer__contacts {
        margin: 41px 0 28px;
    }

    .footer__contacts-list {
        margin: 0;
    }
}

@media screen and (min-width: 319px) and (max-width: 599px) {
    .footer {
        height: 230px;
        grid-template-columns: 100%;
        grid-template-rows: 40px 1fr 60px;
        grid-template-areas:
        "title"
        "contacts"
        "social-media";
    }

    .footer__title {
        font-size: 12px;
        padding: 0;
        font-size: 14px;
        text-align: center;
    }

    .footer__other-contacts {
        width: 250px;
        margin: 0 auto;
        justify-content: center;
    }

    .footer__social-media {
        padding: 0;
        justify-content: center;
        align-content: center;
        align-items: start;
    }

    .footer__contacts-item-icon {
        width: 20px;
        height: 20px;
    }

    .footer__contacts-item-text {
        font-size: 9px;
        margin: 5px 0;
    }

    .footer__other-contacts-title {
        font-size: 9px;
    }
}