.section_about-us-container {
    height: 640px;
    position: relative;
}

.section__title_about-us {
    background-color: rgb(198 217 211);
    color: rgb(0 81 55);
}

.about-us__section {
    display: grid;
    width: 100%;
}

.about-us__mountains {
    background-image: url(../../images/about-us-mountains.svg);
    background-repeat: no-repeat;
    background-size: 25% 25%;
    position: absolute;
    z-index: 1;
    left: 13%;
    right: 0;
    top: 62%;
    bottom: 0;
}

.about-us__text-box {
    width: calc(100% - 80px*2);
    height: 173px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    margin: 0 auto;
}

.about-us__text {
    color: black;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.about-us__graph-box {
    height: 467px;
    display: block;
    width: 100%;
    background-image: url(../../images/about-us-background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    box-sizing: border-box;
    position: relative;
}

.about-us__headers {
    font-size: 30px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 95px;
    padding: 0;
    position: absolute;
    top: 36%;
    left: 10%;
}

.about-us__header {
    padding: 0;
    margin: 0;
}

.about-us__global {
    width: 440px;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 16px;
    position: absolute;
    top: 22%;
    left: 36.5%;
}

.about-us__graph-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    background-color: rgba(0, 81, 55, .7);
    width: 134px;
    height: 134px;
    border-radius: 50%;
}

.about-us__graph-text_theme_light {
    background-color: rgba(198, 217, 211, .7);
}

.about-us__graph-item {
    width: 140px;
    margin: 0;
    padding: 0;
}

.about-us__graph-item_highlighted {
    font-size: 38px;
}

.about-us__local {
    color:rgb(0 81 55);
    width: 440px;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 16px;
    position: absolute;
    top: 56%;
    left: 36.5%;
}

@media screen and (max-width: 1201px) {
    .about-us__mountains {
        left: 1%;
        top: 63%;
    }
}

@media screen and (max-width: 1049px) {
    .about-us__mountains {
        background-size: 20% 20%;
        left: 4%;
        top: 62%;
    }
}

@media screen and (max-width: 921px) {
    .about-us__graph-box {
        height: 415px;
    }

    .section_about-us-container {
        height: 588px;
    }

    .about-us__headers {
        font-size: 22px;
    }

    .about-us__graph-text {
        font-size: 12px;
        width: 118px;
        height: 118px;
    }

    .about-us__graph-item {
        width: 120px;
    }

    .about-us__mountains {
        top: 63%;
    }
}

@media screen and (max-width: 863px) {
    .about-us__mountains {
        left: 2%;
    }
}

@media screen and (max-width: 802px) {
    .about-us__text-box {
        width: calc(100% - 50px*2);
    }

    .about-us__headers {
        font-size: 20px;
    }

    .about-us__graph-text {
        font-size: 12px;
        width: 110px;
        height: 110px;
    }

    .about-us__global {
        width: 371px;
        gap: 21px;
    }

    .about-us__local {
        width: 371px;
        gap: 21px;
        top: 58%;
    }
}

@media screen and (max-width: 769px) {
    .section_about-us-container {
        height: 543px;
    }

    .about-us__graph-box {
        height: 370px;
    }

    .about-us__text {
        font-size: 12px;
    }

    .about-us__graph-text {
        font-size: 11px;
        width: 105px;
        height: 105px;
    }

    .about-us__graph-item {
        width: 105px;
    }

    .about-us__graph-item_highlighted {
        font-size: 36px;
    }

    .about-us__mountains {
        top: 65%;
    }
}

@media screen and (max-width: 731px) {
    .section_about-us-container {
        height: 464px;
    }

    .about-us__text-box {
        height: 130px;
    }

    .about-us__graph-box {
        height: 335px;
    }

    .about-us__headers {
        font-size: 17px;
        gap: 85px;
    }

    .about-us__graph-text {
        font-size: 10px;
        width: 95px;
        height: 95px;
    }

    .about-us__global {
        width: 313px;
        gap: 14px;
    }

    .about-us__local {
        width: 313px;
        gap: 14px;
    }

    .about-us__graph-item {
        width: 100px;
    }

    .about-us__graph-item_highlighted {
        font-size: 30px;
    }

    .about-us__mountains {
        top: 62%;
    }
}

@media screen and (max-width: 641px) {
    .section_about-us-container {
        height: 397px;
    }

    .about-us__graph-box {
        height: 266px;
    }

    .about-us__text {
        font-size: 10px;
    }

    .about-us__headers {
        gap: 52px;
    }

    .about-us__graph-text {
        font-size: 8px;
        width: 77px;
        height: 77px;
    }

    .about-us__graph-item_highlighted {
        font-size: 25px;
    }

    .about-us__graph-item {
        width: 80px;
    }

    .about-us__global {
        width: 241px;
        gap: 5px;
    }

    .about-us__local {
        width: 241px;
        gap: 5px;
    }

    .about-us__mountains {
        top: 64%;
        left: 4%;
    }
}

@media screen and (min-width: 599px) and (max-width: 610px) {
    .section_about-us-container {
        height: 337px;
    }

    .about-us__text {
        font-size: 8px;
    }

    .about-us__text-box {
        width: calc(100% - 40px*2);
        height: 92px;
        gap: 10px;
    }

    .about-us__graph-box {
        height: 245px;
    }

    .about-us__headers {
        font-size: 15px;
        gap: 56px;
    }

    .about-us__graph-text {
        font-size: 8px;
        width: 70px;
        height: 70px;
    }

    .about-us__global {
        width: 226px;
        gap: 8px;
    }

    .about-us__graph-item_highlighted {
        font-size: 20px;
    }

    .about-us__graph-item {
        width: 80px;
    }

    .about-us__local {
        width: 226px;
        gap: 8px;
    }

    .about-us__mountains {
        background-size: 20% 20%;
        left: 5%;
        top: 63%;
    }
}

@media screen and (max-width: 599px) {
    .about-us__mountains {
        display: none;
    }

    .section_about-us-container {
        height: 484px;
    }

    .about-us__graph-box {
        height: 314px;
    }

    .about-us__headers {
        gap: 66px;
        font-size: 22px;
    }

    .about-us__global {
        width: 291px;
        gap: 9px;
    }

    .about-us__local {
        width: 291px;
        gap: 9px;
    }

    .about-us__graph-text {
        font-size: 10px;
        width: 88px;
        height: 88px;
    }

    .about-us__text-box {
        width: calc(100% - 34px*2);
    }

}

@media screen and (max-width: 526px) {
    .about-us__headers {
        gap: 70px;
        font-size: 16px;
    }
}

@media screen and (max-width: 467px) {
    .section_about-us-container {
        height: 448px;
    }

    .about-us__graph-box {
        height: 278px;
    }

    .about-us__headers {
        gap: 63px;
    }

    .about-us__graph-text {
        font-size: 9px;
        width: 80px;
        height: 80px;
    }
}

@media screen and (max-width: 425px) {
    .about-us__graph-box {
        height: 257px;
    }

    .section_about-us-container {
        height: 427px;
    }

    .about-us__headers {
        font-size: 13px;
    }

    .about-us__graph-text {
        font-size: 8px;
        width: 73px;
        height: 73px;
    }

    .about-us__text-box {
        width: calc(100% - 27px*2);
    }
}

@media screen and (max-width: 396px) {
    .section_about-us-container {
        height: 382px;
    }

    .about-us__graph-box {
        height: 212px;
    }

    .about-us__headers {
        font-size: 11px;
    }

    .about-us__graph-text {
        font-size: 7px;
        width: 64px;
        height: 64px;
    }

    .about-us__graph-item {
        width: 65px;
    }

    .about-us__graph-item_highlighted {
        font-size: 20px;
    }

    .about-us__text-box {
        width: calc(100% - 17px*2);
    }
}

@media screen and (max-width: 384px) {
    .about-us__text-box {
        width: calc(100% - 12px*2);
    }
}

@media screen and (max-width: 377px) {
    .about-us__text {
        font-size: 10px;
    }

    .about-us__text-box {
        width: calc(100% - 27px*2);
        height: 116px;
    }

    .section_about-us-container {
        height: 351px;
        grid-template-rows: 40px 311px;
    }

    .about-us__graph-box {
        height: 195px;
    }

    .about-us__headers {
        font-size: 12px;
        gap: 40px;
        left: 6%;
    }

    .about-us__global {
        width: 195px;
        gap: 5px;
    }

    .about-us__graph-text {
        font-size: 7px;
        width: 60px;
        height: 60px;
    }

    .about-us__graph-item_highlighted {
        font-size: 15px;
    }

    .about-us__graph-item {
        width: 60px;
    }

    .about-us__local {
        width: 195px;
        gap: 5px;
    }
}


@media screen and (max-width: 360px) {
    .about-us__text-box {
        width: calc(100% - 20px*2);
    }
}