.section_advantages-content {
    height: 368px;
}

.advantages__section {
    width: 100%;
    display: flex;
    position: relative;
}

.advantages__mountains {
    background-image: url(../../images/advantages-mountains.svg);
    background-repeat: no-repeat;
    background-size: 85% 85%;
    position: absolute;
    z-index: 1;
    left: 20%;
    right: 0;
    top: 0;
    bottom: 36%;
}

.advantages__list {
    width: calc(100% - 25px*2);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 0 auto;
    padding: 0;
    gap: 10px;
}

.advantages__item {
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

.advantages__icon {
    width: 100px;
}

.advantages__text {
    color: rgb(0 81 55);
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    margin: 0;
    padding: 0;
    font-size: 16px;
}

@media screen and (max-width: 1241px) {
    .advantages__text {
        font-size: 14px;
    }
}

@media screen and (max-width: 963px) {
    .advantages__item {
        height: 206px;
    }

    .advantages__icon {
        width: 80px;
    }

    .section_advantages-content {
        height: 292px;
    }

    .advantages__text {
        font-size: 12px;
    }

}

@media screen and (max-width: 826px) {
    .section_advantages-content {
        height: 265px;
    }

    .advantages__item {
        height: 200px;
    }

    .advantages__text {
        font-size: 11px;
    }
}

@media screen and (max-width: 764px) {
    .section_advantages-content {
        height: 236px;
    }

    .advantages__icon {
        width: 70px;
    }

    .advantages__text {
        font-size: 10px;
    }

    .advantages__item {
        height: 170px;
    }
}

@media screen and (max-width: 710px) {
    .advantages__text {
        font-size: 9px;
    }

    .advantages__icon {
        width: 58px;
    }

    .section_advantages-content {
        height: 202px;
    }

    .advantages__item {
        height: 150px;
    }
}

@media screen and (max-width: 646px) {
    .advantages__text {
        font-size: 8px;
    }

    .advantages__icon {
        width: 54px;
    }

    .section_advantages-content {
        height: 183px;
    }

    .advantages__item {
        height: 141px;
    }
}

@media screen and (min-width: 599px) and (max-width: 618px) {
    .section_advantages-content {
        height: 193px;
    }

    .advantages__icon {
        width: 40px;
    }

    .advantages__text {
        font-size: 8px;
    }

    .advantages__list {
        width: calc(100% - 15px*2);
    }

    .advantages__mountains {
        background-size: 75% 75%;
    }

    .advantages__item {
        height: 110px;
    }
}

@media screen and (max-width: 599px) {
    .section_advantages-content {
        height: 219px;
        grid-template-rows: 40px 179px;
    }

    .advantages__icon {
        width: 60px;
    }

    .advantages__text {
        font-size: 10px;
    }
}

@media screen and (max-width: 548px) {
    .advantages__text {
        font-size: 9px;
    }
}

@media screen and (max-width: 510px) {
    .advantages__text {
        font-size: 8px;
    }

    .advantages__list {
        width: calc(100% - 15px*2);
    }
}

@media screen and (max-width: 494px) {
    .advantages__icon {
        width: 50px;
    }

    .advantages__list {
        width: calc(100% - 10px*2);
        gap: 5px;
    }
}

@media screen and (max-width: 415px) {
    .advantages__list {
        width: calc(100% - 5px*2);
        gap: 3px;
    }
}

@media screen and (max-width: 399px) {
    .advantages__list {
        width: calc(100% - 15px*2);
    }
}

@media screen and (max-width: 386px) {
    .advantages__list {
        width: calc(100% - 9px*2);
    }
}

@media screen and (max-width: 373px) {
    .advantages__icon {
        width: 40px;
    }

    .advantages__text {
        font-size: 11px;
    }

    .section_advantages-content {
        height: 237px;
        grid-template-rows: 40px 197px;
    }

    .advantages__item {
        height: 162px;
    }
}

@media screen and (min-width: 319px) and (max-width: 338px) {
    .section_advantages-content {
        height: 182px;
        grid-template-rows: 40px 125px;
    }

    .advantages__list {
        width: calc(100% - 8px*2);
        gap: 5px;
    }

    .advantages__item {
        height: 250px;
        gap: 10px;
    }

    .advantages__icon {
        width: 40px;
    }

    .advantages__text {
        font-size: 9px;
    }

    .advantages__item {
        height: 140px;
        gap: 5px;
    }
}