.section_anticrisis-content {
    height: 450px;
    margin-top: -6px;
}

.anticrisis__section {
    display: block;
    width: 100%;
    background-image: url(../../images/anticrisis-background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

.anticrisis__graph {
    background-image: url(../../images/anticrisis-graph.svg);
    background-repeat: no-repeat;
    background-size: 75% 75%;
    background-position: top 49px left 96px;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.anticrisis__text-block {
    display: flex;
    flex-direction: column;
    padding: 0;
    position: absolute;
    width: 273px;
}

.anticrisis__text-block_position_left {
    top: 36%;
    right: 0;
    bottom: 0;
    left: 4%;
}

.anticrisis__text-block_position_top-right {
    top: 20%;
    right: 0;
    bottom: 0;
    left: 68%;
    width: 186px;
}

.anticrisis__text-block_position_bottom-right {
    top: 81%;
    right: 0;
    bottom: 0;
    left: 60%;
}

.anticrisis__text {
    color: black;
    font-size: 22px;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.anticrisis__text_highlited {
    color: rgb(0 81 55);
}

@media screen and (max-width: 1049px) {
    .anticrisis__text {
        font-size: 19px;
    }

    .anticrisis__text-block_position_top-right {
        left: 70%;
    }

    .anticrisis__text-block_position_bottom-right {
        left: 63%;
    }
}

@media screen and (max-width: 984px) {
    .anticrisis__text-block_position_top-right {
        left: 72%;
    }

    .anticrisis__text-block_position_left {
        left: 3%;
    }
}

@media screen and (max-width: 935px) {
    .anticrisis__text-block_position_top-right {
        left: 74%;
        width: 137px;
    }

    .anticrisis__text-block_position_bottom-right {
        top: 83%;
    }

    .anticrisis__text-block_position_left {
        top: 23%;
    }
}

@media screen and (max-width: 863px) {
    .anticrisis__text {
        font-size: 17px;
    }

    .anticrisis__text-block_position_top-right {
        left: 76%;
    }
}

@media screen and (max-width: 806px) {
    .section_anticrisis-content {
        height: 405px;
    }

    .anticrisis__graph {
        background-size: 70% 70%;
    }

    .anticrisis__text-block_position_bottom-right {
        top: 79%;
    }

    .anticrisis__text-block_position_top-right {
        left: 74%;
    }
}

@media screen and (max-width: 777px) {
    .anticrisis__text {
        font-size: 16px;
    }
}

@media screen and (max-width: 729px) {
    .section_anticrisis-content {
        height: 365px;
    }

    .anticrisis__graph {
        background-size: 68% 70%;
    }

    .anticrisis__text {
        font-size: 15px;
    }
}

@media screen and (max-width: 672px) {
    .anticrisis__text {
        font-size: 14px;
    }

    .anticrisis__graph {
        background-size: 65% 70%;
    }
}

@media screen and (min-width: 599px) and (max-width: 640px) {
    .section_anticrisis-content {
        height: 243px;
    }

    .anticrisis__graph {
        background-size: 75% 75%;
        background-position: top 26px left 43px;
    }

    .anticrisis__text {
        font-size: 12px;
    }

    .anticrisis__text-block_position_left {
        top: 39%;
    }

    .anticrisis__text-block_position_top-right {
        top: 23%;
        left: 67%;
    }

    .anticrisis__text-block_position_bottom-right {
        top: 79%;
    }
}

@media screen and (max-width: 599px) {
    .section_anticrisis-content {
        height: 294px;
        grid-template-rows: 40px 254px;
    }

    .anticrisis__graph {
        background-size: 65% 75%;
        background-position: top 28px left 96px;
    }

    .anticrisis__text {
        font-size: 16px;
    }

    .anticrisis__text-block_position_bottom-right {
        top: 72%;
    }

    .anticrisis__text-block_position_top-right {
        left: 66%;
    }

    .anticrisis__text-block_position_left {
        top: 25%;
        left: 6%;
    }
}

@media screen and (max-width: 599px) {
    .anticrisis__text {
        font-size: 14px;
    }

    .anticrisis__graph {
        background-position: top 28px left 85px;
    }
}

@media screen and (max-width: 525px) {
    .anticrisis__text {
        font-size: 13px;
    }

    .anticrisis__graph {
        background-position: top 28px left 81px;
    }
}

@media screen and (max-width: 525px) {
    .anticrisis__text {
        font-size: 12px;
    }

    .anticrisis__graph {
        background-position: top 28px left 77px;
    }
}

@media screen and (max-width: 482px) {
    .anticrisis__graph {
        background-size: 64% 73%;
    }

    .anticrisis__text-block_position_top-right {
        left: 68%;
    }

    .anticrisis__text-block_position_bottom-right {
        top: 76%;
    }

    .anticrisis__text-block_position_left {
        left: 4%;
    }
}

@media screen and (max-width: 450px) {
    .anticrisis__text-block_position_top-right {
        left: 71%;
    }

    .anticrisis__text-block_position_bottom-right {
        top: 78%;
    }

    .anticrisis__text-block_position_left {
        left: 2%;
    }
}

@media screen and (max-width: 417px) {
    .anticrisis__graph {
        background-size: 62% 68%;
    }

    .section_anticrisis-content {
        height: 281px;
        grid-template-rows: 40px 241px;
    }

    .anticrisis__text-block_position_bottom-right {
        top: 76%;
        left: 60%;
    }
}

@media  screen and (max-width: 381px) {
    .anticrisis__graph {
        background-size: 70% 70%;
        background-position: top 28px left 57px;
    }

    .anticrisis__text {
        font-size: 11px;
    }

    .anticrisis__text-block_position_bottom-right {
        top: 79%;
        left: 60%;
    }

    .anticrisis__text-block_position_left {
        top: 20%;
        left: 1%;
    }
}

@media screen and (max-width: 362px) {
    .anticrisis__graph {
        background-size: 70% 65%;
        background-position: top 39px left 58px;
    }

    .anticrisis__text-block_position_left {
        left: 3%;
    }
}

@media screen and (max-width: 349px) {
    .anticrisis__text {
        font-size: 10px;
    }

    .anticrisis__graph {
        background-size: 70% 65%;
        background-position: top 39px left 56px;
    }
}


@media screen and (max-width: 336px) {
    .section_anticrisis-content {
        height: 235px;
        grid-template-rows: 40px 195px;
    }

    .anticrisis__graph {
        background-size: 65% 65%;
        background-position: top 25px center;
        position: absolute;
    }

    .anticrisis__text-block {
        width: 126px;
    }

    .anticrisis__text-block_position_top-right {
        left: 70%;
        width: 88px;
    }

    .anticrisis__text-block_position_bottom-right {
        top: 76%;
        left: 58%;
    }

    .anticrisis__text-block_position_left {
        left: 2%;
    }
}