@import url(./vendor/normalize.css);
@import url(./vendor/fonts/fonts.css);

/*переиспользуемые css-свойства */
.section {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.section__title {
  width: 45%;
  display: flex;
  align-items: center;
  background-color: rgb(0 81 55);
  margin: 0;
  padding-left: 80px;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 28px;
}

@media screen and (max-width: 1200px) {
  .section__title {
    width: 28%;
    font-size: 16px;
    padding-left: 40px;
  }
}

@media screen and (max-width: 863px) {
  .section__title {
    width: 26%;
    font-size: 14px;
    padding-left: 24px;
  }
}

@media screen and (max-width: 729px) {
  .section__title {
    font-size: 12px;
}
}

@media screen and (min-width: 599px) and (max-width: 640px) {
  .section__title {
    width: 28%;
    font-size: 12px;
    padding-left: 17px;
  }
}

@media screen and (min-width: 319px) and (max-width: 599px) {
  .section {
    display: grid;

  }

  .section__title {
    height: 40px;
    width: 100%;
    padding-left: 0;
    justify-content: center;
    font-size: 14px;
  }
}