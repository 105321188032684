.navbar__list {
    position: absolute;
	bottom: 24%;
	left: 22%;
	z-index: 1;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
}

.navbar__item {
    margin: 0;
    background-image: url(../../images/header-button.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 10px 0 12px;
    border-radius: 4px;
    box-sizing: border-box;
    width: 140px;
    font-size: 13px;
    font-weight: bolder;
}

.navbar__link {
    text-decoration: none;
    color: #fff;
}

.navbar__item:active {
    font-size: 15px;
    background-image: url(../../images/header-button-active.svg); 
}

@media screen and (min-width: 1201px) and (max-width: 1279px) {
    .navbar__list {
        left: 20%;
    }
}

@media screen and (min-width: 1181px) and (max-width: 1200px) {
    .navbar__list {
        left: 19%;
    }
}

@media screen and (min-width: 1149px) and (max-width: 1180px) {
    .navbar__list {
        left: 18%;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1148px) {
    .navbar__list {
        left: 17%;
    }
}

@media screen and (min-width: 1070px) and (max-width: 1099px){
    .navbar__list {
        left: 16%;
    }
}

@media screen and (min-width: 1033px) and (max-width: 1069px) {
    .navbar__list {
        left: 15%;
    }
}

@media screen and (min-width: 1022px) and (max-width: 1032px) {
    .navbar__list {
        left: 14%;
    }
}

@media screen and (max-width: 1022px) {
    .navbar__list {
        left: 13%;
    }
}

@media screen and (max-width: 973px) {
    .navbar__list {
        left: 12%;
    }
}

@media screen and (max-width: 957px) {
    .navbar__list {
        left: 11%;
    }
}

@media screen and (max-width: 939px) {
    .navbar__list {
        left: 10%;
    }
}

@media screen and (max-width: 910px) {
    .navbar__list {
        bottom: 22%;
        left: 10%;
        width: 80%;
        gap: 5px;
    }

    .navbar__item {
        padding: 7px 0 6px;
        width: 110px;
        font-size: 10px;
    }
}


@media screen and (min-width: 599px) and (max-width: 767px) {
    .navbar__list {
        bottom: 22%;
        left: 15%;
        width: 70%;
        gap: 5px;
    }

    .navbar__item {
        padding: 7px 0 6px;
        width: 82px;
        font-size: 8px;
    }
}

@media screen and (min-width: 319px) and (max-width: 599px) {
    .navbar__list {
        display: none;
    }
}