.App {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #fff;
  min-width: 320px;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
