@font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    src: url(./HelveticaNeueCyr-Roman.woff) format('woff'),
         url(./HelveticaNeueCyr-Roman.woff2) format('woff2');
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(./HelveticaNeueCyr-Medium.woff) format('woff'),
         url(./HelveticaNeueCyr-Medium.woff2) format('woff2');
}

@font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(./HelveticaNeueCyr-Bold.woff) format('woff'),
         url(./HelveticaNeueCyr-Bold.woff2) format('woff2');
}