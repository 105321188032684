.header {
    max-width: 1280px;
    width: 100%;
    height: 583px;
    padding: 0;
    margin: -30px auto 0;
    overflow: hidden;
    position: relative;
    transition: opacity 0.3s linear;
}

.header__background {
    padding: 0;
    margin: 0;
    position: absolute;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.header__mountains {
    margin: 0;
    padding: 0;
    width: 100%;
	margin-top: -25%;
	transform-origin: bottom center;
	animation: grow 150000ms infinite ease;
}

@keyframes grow {
	0% {
		transform: scale(1);
	}
    50% {
        transform: scale(2);
    }
	0% {
		transform: scale(1);
	}
}

.header__logo {
    position: absolute;
	bottom: 79%;
	left: 39%;
	z-index: 1;
    width: 260px;
    margin: 0;
    padding: 0;
}

.header__title-frame {
    margin: 0;
    padding: 0;
    background-image: url(../../images/header-white-elements.svg);
    background-repeat: no-repeat;
    background-position: top 36% center;
    background-size: 50% 50%;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    object-fit: cover;
}

.header__title-box {
    width: 100%;
    position: absolute;
    bottom: 51%;
	left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header__title {
    font-size: 48px;
    padding: 0;
    margin: 0;
    font-weight: bold;
}

.header__title-slogan {
    font-size: 36px;
    margin: 0;
    padding: 0;
    font-weight: normal;
}

@media screen and (min-width: 1237px) and (max-width: 1279px) {
    .header {
        height: 570px;
    }
}

@media screen and (min-width: 1205px) and (max-width: 1236px) {
    .header {
        height: 560px;
    }
}

@media screen and (min-width: 1145px) and (max-width: 1204px) {
    .header {
        height: 530px;
    }
}

@media screen and (min-width: 1129px) and (max-width: 1144px) {
    .header {
        height: 517px;
    }
}

@media screen and (max-width: 1128px) {
    .header {
        height: 497px;
    }

    .header__logo {
        bottom: 78%;
        width: 225px;
    }

    .header__title-box {
        bottom: 49%;
    }

    .header__title {
        font-size: 45px;
    }
}

@media screen and (min-width: 1021px) and (max-width: 1080px) {
    .header {
        height: 483px;
        margin: -32px auto 0;
    }

    .header__logo {
        bottom: 78%;
        width: 225px;
    }

    .header__title-box {
        bottom: 50%;
    }

    .header__title {
        font-size: 45px;
    }

    .header__title-slogan {
        font-size: 34px;
    }
    
}

@media screen and (max-width: 1020px) {
    .header {
        height: 459px;
    }

    .header__title-box {
        bottom: 49%;
    }

    .header__title {
        font-size: 40px;
    }

    .header__title-slogan {
        font-size: 34px;
    }

    .header__logo {
        left: 38%;
    }
}

@media screen and (min-width: 940px) and (max-width: 972px) {
    .header {
        height: 445px;
    }
}

@media screen and (max-width: 940px) {
    .header {
        height: 435px;
    }
}

@media screen and (max-width: 918px) {
    .header {
        height: 425px;
    }

    .header__title {
        font-size: 38px;
    }

    .header__title-slogan {
        font-size: 30px;
    }

    .header__logo {
        left: 37%;
    }
}

@media screen and (max-width: 908px) {
    .header {
        height: 403px;
    }

    .header__logo {
        left: 36%;
    }
}

@media screen and (max-width: 849px) {
    .header {
        height: 390px;
    }
}

@media screen and (max-width: 820px) {
    .header {
        height: 380px;
    }

    .header__title {
        font-size: 35px;
    }

    .header__title-slogan {
        font-size: 27px;
    }

    .header__logo {
        left: 35%;
    }
}

@media screen and (max-width: 799px) {
    .header {
        height: 360px;
    }

    .header__logo {
        width: 174px;
        bottom: 79%;
        left: 38%;
    }
}

@media screen and (max-width: 754px) {
    .header {
        height: 350px;
    }

    .header__title {
        font-size: 32px;
    }

    .header__title-slogan {
        font-size: 25px;
    }
}

@media screen and (max-width: 733px) {
    .header {
        height: 340px;
    }
}

@media screen and (max-width: 711px) {
    .header {
        height: 330px;
    }

    .header__logo {
        left: 37%;
    }
}

@media screen and (max-width: 687px) {
    .header {
        height: 320px;
    }
}

@media screen and (max-width: 667px) {
    .header {
        height: 315px;
    }

    .header__logo {
        left: 36%;
    }

    .header__title {
        font-size: 28px;
    }

    .header__title-slogan {
        font-size: 22px;
    }
}

@media screen and (max-width: 655px) {
    .header {
        height: 310px;
    }
}

@media screen and (max-width: 644px) {
    .header {
        height: 300px;
    }
}

@media screen and (max-width: 638px) {
    .header {
        height: 320px;
        margin: -32px auto 0;
    }

    .header__logo {
        width: 136px;
        bottom: 72%;
        left: 38%;
    }

    .header__title-box {
        bottom: 45%;
    }

    .header__title {
        font-size: 24px;
    }

    .header__title-slogan {
        font-size: 18px;
    }

    .header__title-frame {
        background-position: top 48% center;
        background-size: 50% 50%;
    }
}

@media screen and (min-width: 599px) and (max-width: 620px) {
    .header {
        height: 290px;
    }

    .header__title-frame {
        background-position: top 40% center;
    }

    .header__title-box {
        bottom: 48%;
    }

    .header__logo {
        bottom: 75%;
    }
}

@media screen and (max-width: 599px) {
    .header {
        height: 300px;
        margin: -32px auto 0;
    }

    .header__title-frame {
        background-position: top 64% center;
        background-size: 80% 60%;
    }

    .header__title-box {
        bottom: 36%;
    }

    .header__title {
        font-size: 32px;
    }

    .header__title-slogan {
        font-size: 24px;
    }
}

@media screen and (max-width: 584px) {
    .header {
        height: 288px;
    }

    .header__logo {
        left: 37%;
    }
}

@media screen and (max-width: 556px) {
    .header {
        height: 278px;
    }
}

@media screen and (max-width: 533px) {
    .header {
        height: 268px;
    }

    .header__title {
        font-size: 30px;
    }

    .header__title-slogan {
        font-size: 22px;
    }

    .header__logo {
        left: 36%;
    }
}

@media screen and (max-width: 510px) {
    .header {
        height: 258px;
    }
}

@media screen and (max-width: 488px) {
    .header {
        height: 247px;
    }

    .header__logo {
        left: 35%;
        bottom: 70%;
    }

    .header__title-frame {
        background-position: top 66% center;
        background-size: 70% 60%;
    }

    .header__title-box {
        bottom: 34%;
    }
}

@media screen and (max-width: 462px) {
    .header {
        height: 242px;
    }

    .header__title {
        font-size: 28px;
    }

    .header__title-slogan {
        font-size: 20px;
    }
}

@media screen and (max-width: 453px) {
    .header {
        margin: -55px auto 0;
    }

    .header__title-frame {
        background-position: top 76% center;
    }

    .header__logo {
        width: 120px;
        bottom: 65%;
        left: 36%;
    }

    .header__title-box {
        bottom: 31%;
    }
}

@media screen and (max-width: 416px) {
    .header {
        height: 247px;
        margin: -75px auto 0;
    }

    .header__logo {
        width: 110px;
        bottom: 61%;
        left: 36%;
    }

    .header__title-frame {
        background-position: top 76% left 61%;
        background-size: 100% 50%;
    }

    .header__title-box {
        bottom: 28%;
    }
}

@media screen and (max-width: 412px) {
    .header {
        height: 243px;
    }

    .header__logo {
        width: 97px;
        bottom: 60%;
        left: 37%;
    }

    .header__title-box {
        bottom: 32%;
    }

    .header__title {
        font-size: 18px;
    }

    .header__title-slogan {
        font-size: 14px;
    }
}

@media screen and (max-width: 484px) {
    .header {
        height: 234px;
    }
}

@media screen and (max-width: 386px) {
    .header {
        height: 223px;
    }
}

@media screen and (min-width: 319px) and (max-width: 359px) {
    .header {
        height: 205px;
    }

    .header__title-box {
        bottom: 30%;
    }

    .header__logo {
        left: 35%;
    }
}