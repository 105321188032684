.section_solutions-content {
    height: 545px;
    position: relative;
}

.solutions__mountains {
    background-image: url(../../images/solutions-mountains.svg);
    background-repeat: no-repeat;
    background-size: 45% 45%;
    position: absolute;
    z-index: 1;
    top: 53%;
    left: 0%;
    right: 10%;
    bottom: 0;
}

.section__title_solutions {
    background-color: rgb(198 217 211);
    color: rgb(0 81 55);
}

.solutions__section {
    width: 100%;
    background-image: url(../../images/solutions-background.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
}

.solutions__graph {
    background-image: url(../../images/solutions-graph.svg);
    position: absolute;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: 65% 65%;
    background-position: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.solutions__graph-text {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    font-size: 17px;
    text-align: left;
    width: 300px;
    font-weight: bold;
    position: absolute;
}

.solutions__graph-text_position_top-center {
    top: 9%;
    right: 0;
    bottom: 0;
    left: 33%;
    text-align: center;
}

.solutions__graph-text_position_right-top {
    top: 29%;
    right: 0;
    bottom: 0;
    left: 67%;
    width: 246px;
}

.solutions__graph-text_position_right-center {
    top: 47%;
    right: 0;
    bottom: 0;
    left: 71%;
    width: 246px;
}

.solutions__graph-text_position_right-bottom {
    top: 66%;
    right: 0;
    bottom: 0;
    left: 67%;
    width: 246px;
}

.solutions__graph-text_position_bottom-center {
    top: 84%;
    right: 0;
    bottom: 0;
    left: 33%;
    text-align: center;
}

.solutions__graph-text_position_left-top {
    top: 29%;
    right: 0;
    bottom: 0;
    left: 8%;
    width: 200px;
    text-align: right;
}

.solutions__graph-text_position_left-center {
    top: 46%;
    right: 0;
    bottom: 0;
    left: 4%;
    text-align: right;
    width: 220px;
}

.solutions__graph-text_position_left-bottom {
    top: 65%;
    right: 0;
    bottom: 0;
    left: 8%;
    text-align: right;
    width: 220px;
}

.solutions__graph-item {
    color:black;
    margin: 0;
    padding: 0;
}

.solutions__graph-item_highlighted {
    color:rgb(0 81 55);
}

@media screen and (max-width: 1260px) {
    .solutions__graph-text_position_left-top {
        left: 8%;
        width: 190px;
    }

    .solutions__graph-text_position_left-center {
        left: 4%;
        width: 208px;
    }

    .solutions__graph-text_position_left-bottom {
        left: 8%;
        width: 208px;
    }
}

@media screen and (max-width: 1241px) {
    .solutions__graph-text {
        font-size: 15px;
    }

    .solutions__graph-text_position_right-bottom {
        left: 68%;
    }

    .solutions__graph-text_position_left-top {
        width: 180px
    }

    .solutions__graph-text_position_left-center {
        width: 190px;
    }

    .solutions__graph-text_position_left-bottom {
        width: 190px;
    }

    .solutions__graph-text_position_right-top {
        left: 68%;
    }

    .solutions__graph-text_position_right-center {
        left: 72%;
        width: 160px;
    }
}

@media screen and (min-width: 1139px) and (max-width: 1200px) {
    .solutions__graph-text_position_bottom-center {
        left: 34%;
    }

    .solutions__graph-text_position_top-center {
        left: 34%;
    }

    .solutions__graph-text_position_left-top {
        width: 216px;
    }

    .solutions__graph-text_position_left-center {
        width: 226px;
    }

    .solutions__graph-text_position_left-bottom {
        width: 223px;
    }

    .solutions__mountains {
        top: 51%;
        right: 49%;
    }
}

@media screen and (max-width: 1139px) {
    .solutions__graph-text_position_left-top {
        width: 196px;
    }

    .solutions__graph-text_position_left-center {
        width: 208px;
    }

    .solutions__graph-text_position_left-bottom {
        width: 207px;
    }

    .solutions__mountains {
        top: 50%;
        right: 50%;
    }
}

@media screen and (min-width: 1037px) and (max-width: 1098px) {
    .solutions__graph-text_position_top-center {
        left: 33%;
    }

    .solutions__graph-text_position_bottom-center {
        left: 33%;
    }

    .solutions__graph-text_position_left-top {
        width: 176px;
    }

    .solutions__graph-text_position_left-center {
        width: 185px;
    }

    .solutions__graph-text_position_left-bottom {
        width: 184px;
    }
}

@media screen and (max-width: 1037px) {
    .solutions__graph-text_position_top-center {
        left: 31%;
    }

    .solutions__graph-text_position_bottom-center {
        left: 31%;
    }

    .solutions__graph-text_position_left-top {
        left: 6%;
    }

    .solutions__graph-text_position_left-center {
        left: 2%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 6%;
    }
}

@media screen and (max-width: 1033px) {
    .section_solutions-content {
        height: 496px;
    }
}

@media screen and (max-width: 984px) {
    .section_solutions-content {
        height: 426px;
    }

    .solutions__graph-text_position_top-center {
        top: 8%;
        left: 30%;
    }

    .solutions__graph-text_position_bottom-center {
        left: 30%;
    }
}

@media screen and (max-width: 967px) {
    .solutions__graph-text_position_right-top {
        left: 67%;
    }

    .solutions__graph-text_position_right-center {
        left: 70%;
    }

    .solutions__graph-text_position_right-bottom {
        left: 67%;
    }
}

@media screen and (max-width: 926px) {
    .section_solutions-content {
        height: 393px;
    }

    .solutions__graph-text_position_left-top {
        left: 4%;
    }

    .solutions__graph-text_position_left-center {
        width: 198px;
    }

    .solutions__graph-text_position_left-bottom {
        width: 198px;
    }

    .solutions__mountains {
        top: 52%;
    }
}

@media screen and (min-width: 769px) and (max-width: 898px) {
    .solutions__graph-text {
        font-size: 13px;
    }

    .solutions__graph-text_position_left-top {
        width: 195px;
    }

    .solutions__graph-text_position_left-center {
        width: 194px;
    }

    .solutions__graph-text_position_left-bottom {
        width: 191px;
    }

    .solutions__graph-text_position_bottom-center {
        left: 29%;
    }

    .solutions__graph-text_position_top-center {
        left: 29%;
    }
}

@media screen and (max-width: 863px) {
    .solutions__mountains {
        top: 50%;
    }

    .solutions__graph-text_position_top-center {
        left: 28%;
    }

    .solutions__graph-text_position_bottom-center {
        left: 28%;
    }

    .solutions__graph-text_position_left-top {
        left: 3%;
    }
}

@media screen and (max-width: 847px) {
    .solutions__graph-text_position_left-center {
        width: 183px;
    }

    .solutions__graph-text_position_left-bottom {
        width: 181px;
    }
}

@media screen and (max-width: 837px) {
    .solutions__graph-text_position_left-top {
        left: 2%;
    }

    .solutions__graph-text_position_left-center {
        left: 1%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 5%;
    }
}

@media screen and (max-width: 805px) {
    .solutions__graph-text_position_top-center {
        left: 27%;
    }

    .solutions__graph-text_position_bottom-center {
        left: 27%;
    }

    .solutions__graph-text_position_left-top {
        left: 1%;
    }

    .solutions__graph-text_position_left-center {
        left: 0%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 4%;
    }
}

@media screen and (max-width: 788px) {
    .solutions__graph-text_position_left-top {
        left: 0%;
    }

    .solutions__graph-text_position_left-center {
        width: 177px;
    }

    .solutions__graph-text_position_left-bottom {
        left: 3%;
    }

    .solutions__graph-text_position_top-center {
        left: 26%;
    }

    .solutions__graph-text_position_bottom-center {
        left: 26%;
    }

    .solutions__graph-text_position_right-center {
        left: 71%;
    }

    .solutions__mountains {
        top: 48%;
    }
}

@media screen and (max-width: 769px) {
    .solutions__graph-text {
        width: 140px;
        font-size: 11px;
    }

    .solutions__graph-text_position_top-center {
        top: 9%;
        left: 39%;
    }

    .solutions__graph-text_position_bottom-center {
        left: 39%;
    }

    .solutions__graph-text_position_right-top {
        left: 68%;
        width: 117px;
    }

    .solutions__graph-text_position_right-center {
        left: 72%;
        width: 114px;
    }

    .solutions__graph-text_position_right-bottom {
        left: 68%;
        width: 174px;
    }

    .solutions__graph-text_position_left-top {
        left: 7%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 9%;
    }

    .solutions__graph-text_position_left-center {
        left: 5%;
    }
}

@media screen and (max-width: 752px) {
    .solutions__graph-text_position_left-top {
        left: 6%;
    }

    .solutions__graph-text_position_left-center {
        left: 4%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 8%;
    }
}

@media screen and (max-width: 730px) {
    .solutions__graph-text_position_left-top {
        left: 5%;
    }

    .solutions__graph-text_position_left-center {
        left: 2%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 6%;
    }

    .solutions__graph-text_position_right-bottom {
        left: 70%;
        width: 163px;
    }

    .solutions__graph-text_position_right-center {
        left: 73%;
    }

    .solutions__graph-text_position_right-top {
        left: 70%;
    }
}

@media screen and (max-width: 710px) {
    .solutions__graph-text {
        font-size: 10px;
    }

    .solutions__graph-text_position_bottom-center {
        left: 38%;
    }

    .solutions__graph-text_position_top-center {
        left: 38%;
    }

    .solutions__graph-text_position_left-top {
        left: 4%;
    }

    .solutions__graph-text_position_left-center {
        left: 1%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 5%;
    }
}

@media screen and (max-width: 686px) {
    .solutions__graph-text_position_left-bottom {
        left: 4%;
    }

    .solutions__graph-text_position_left-center {
        left: 0%;
    }

    .solutions__graph-text_position_left-top {
        left: 3%;
    }
}

@media screen and (max-width: 675px) {
    .solutions__graph-text {
        font-size: 9px;
        width: 115px;
    }

    .solutions__graph-text_position_left-top {
        left: 7%;
    }

    .solutions__graph-text_position_left-center {
        left: 4%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 8%;
    }

    .solutions__graph-text_position_bottom-center {
        left: 39%;
    }

    .solutions__graph-text_position_right-bottom {
        left: 71%;
        width: 126px;
    }

    .solutions__graph-text_position_right-center {
        left: 75%;
    }

    .solutions__graph-text_position_right-top {
        left: 71%;
    }

    .solutions__graph-text_position_top-center {
        top: 11%;
        left: 39%;
    }
}

@media screen and (max-width: 665px) {
    .solutions__graph-text_position_left-top {
        left: 6%;
    }

    .solutions__graph-text_position_left-center {
        left: 3%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 7%;
    }
}

@media screen and (max-width: 650px) {
    .solutions__graph-text_position_left-center {
        left: 2%;
    }

    .solutions__graph-text_position_left-top {
        left: 5%;
    }
}

@media screen and (max-width: 641px) {
    .solutions__graph-text_position_left-top {
        left: 4%;
    }

    .solutions__graph-text_position_left-center {
        left: 1%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 6%;
    }

    .solutions__graph-text_position_right-bottom {
        left: 72%;
    }

    .solutions__graph-text_position_right-center {
        left: 76%;
    }

    .solutions__graph-text_position_right-top {
        left: 72%;
    }
}

@media screen and (min-width: 599px) and (max-width: 632px) {
    .section_solutions-content {
        height: 287px;
    }

    .solutions__graph-text {
        font-size: 9px;
        width: 143px;
    }

    .solutions__graph-text_position_top-center {
        top: 9%;
        left: 35%;
    }

    .solutions__graph-text_position_right-top {
        left: 67%;
    }

    .solutions__graph-text_position_right-center {
        left: 71%;
    }

    .solutions__graph-text_position_right-bottom {
        left: 68%;
    }

    .solutions__graph-text_position_bottom-center {
        left: 35%;
    }

    .solutions__graph-text_position_left-top {
        left: 3%;
        width: 135px;
    }

    .solutions__graph-text_position_left-center {
        left: 0%;
        width: 135px;
    }

    .solutions__graph-text_position_left-bottom {
        left: 4%;
        width: 135px;
    }

    .solutions__mountains {
        background-size: 35% 35%;
        top: 53%;
        right: 32%;
    }
}

@media screen and (max-width: 599px) {
    .section_solutions-content {
        height: 339px;
        grid-template-rows: 40px 299px;
    }

    .solutions__mountains {
        display: none;
    }

    .solutions__graph-text_position_top-center {
        top: 9%;
        left: 40%;
    }

    .solutions__graph-text_position_right-top {
        left: 65%;
    }

    .solutions__graph-text_position_right-center {
        left: 68%;
    }

    .solutions__graph-text_position_right-bottom {
        left: 65%;
    }

    .solutions__graph-text_position_bottom-center {
        left: 40%;
    }

    .solutions__graph-text_position_left-top {
        left: 15%;
    }

    .solutions__graph-text_position_left-center {
        left: 13%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 16%;
    }
}

@media screen and (max-width: 584px) {
    .solutions__graph-text_position_left-bottom {
        left: 15%;
    }

    .solutions__graph-text_position_left-center {
        left: 12%;
    }

    .solutions__graph-text_position_left-top {
        left: 14%;
    }
}

@media screen and (max-width: 565px) {
    .solutions__graph-text_position_left-center {
        left: 11%;
    }

    .solutions__graph-text_position_left-top {
        left: 13%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 14%;
    }
}

@media screen and (max-width: 538px) {
    .solutions__graph-text_position_left-bottom {
        left: 13%;
    }

    .solutions__graph-text_position_left-center {
        left: 10%;
    }

    .solutions__graph-text_position_left-top {
        left: 12%;
    }

    .solutions__graph-text_position_right-bottom {
        left: 66%;
    }

    .solutions__graph-text_position_right-center {
        left: 69%;
    }

    .solutions__graph-text_position_right-top {
        left: 66%;
    }
}

@media screen and (max-width: 528px) {
    .solutions__graph-text_position_left-top {
        left: 11%;
    }

    .solutions__graph-text_position_left-center {
        left: 9%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 12%;
    }
}

@media screen and (max-width: 514px) {
    .solutions__graph-text_position_top-center {
        left: 39%;
    }

    .solutions__graph-text_position_bottom-center {
        left: 39%;
    }

    .solutions__graph-text_position_left-top {
        left: 10%;
    }

    .solutions__graph-text_position_left-center {
        left: 8%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 11%;
    }
}

@media screen and (max-width: 500px) {
    .solutions__graph-text_position_left-top {
        left: 8%;
    }

    .solutions__graph-text_position_left-center {
        left: 6%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 9%;
    }

    .solutions__graph-text_position_right-bottom {
        left: 68%;
    }

    .solutions__graph-text_position_right-center {
        left: 71%;
    }

    .solutions__graph-text_position_right-top {
        left: 68%;
    }
}

@media screen and (max-width: 478px) {
    .solutions__graph-text_position_left-top {
        left: 6%;
    }

    .solutions__graph-text_position_left-center {
        left: 4%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 7%;
    }
}

@media screen and (max-width: 457px) {
    .solutions__graph-text_position_left-top {
        left: 4%;
    }

    .solutions__graph-text_position_left-center {
        left: 2%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 5%;
    }

    .solutions__graph-text_position_right-bottom {
        left: 70%;
    }

    .solutions__graph-text_position_right-center {
        left: 73%;
    }

    .solutions__graph-text_position_right-top {
        left: 70%;
    }
}

@media screen and (max-width: 439px) {
    .solutions__graph-text {
        font-size: 8px;
    }

    .solutions__graph-text_position_bottom-center {
        left: 37%;
    }

    .solutions__graph-text_position_top-center {
        left: 37%;
    }

    .solutions__graph-text_position_left-top {
        left: 3%;
    }

    .solutions__graph-text_position_left-center {
        left: 0%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 4%;
    }
}

@media screen and (max-width: 425px) {
    .solutions__graph-text {
        font-size: 7px;
        width: 87px;
    }

    .solutions__graph-text_position_left-bottom {
        left: 10%;
    }

    .solutions__graph-text_position_left-center {
        left: 5%;
    }

    .solutions__graph-text_position_left-top {
        left: 8%;
    }

    .solutions__graph-text_position_bottom-center {
        left: 40%;
    }

    .solutions__graph-text_position_top-center {
        left: 40%;
    }
}

@media screen and (max-width: 407px) {
    .solutions__graph-text_position_left-top {
        left: 7%;
    }

    .solutions__graph-text_position_left-center {
        left: 4%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 8%;
    }

    .solutions__graph-text_position_right-bottom {
        left: 71%;
    }

    .solutions__graph-text_position_right-center {
        left: 75%;
    }

    .solutions__graph-text_position_right-top {
        left: 71%;
    }
}

@media screen and (max-width: 399px) {
    .solutions__graph-text_position_left-top {
        left: 5%;
    }

    .solutions__graph-text_position_left-center {
        left: 2%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 6%;
    }
}

@media screen and (max-width: 376px) {
    .solutions__graph-text_position_left-bottom {
        left: 4%;
    }

    .solutions__graph-text_position_left-center {
        left: 0%;
    }

    .solutions__graph-text_position_left-top {
        left: 3%;
    }

    .solutions__graph-text_position_bottom-center {
        left: 39%;
    }

    .solutions__graph-text_position_top-center {
        left: 39%;
    }

    .solutions__graph-text_position_right-bottom {
        left: 72%;
    }

    .solutions__graph-text_position_right-center {
        left: 77%;
    }

    .solutions__graph-text_position_right-top {
        left: 72%;
    }
}

@media screen and (max-width: 365px) {
    .solutions__graph {
        background-size: 55% 55%;
    }

    .section_solutions-content {
        height: 326px;
        grid-template-rows: 40px 287px;
    }

    .solutions__graph-text_position_top-center {
        top: 14%;
    }

    .solutions__graph-text_position_bottom-center {
        top: 81%;
    }

    .solutions__graph-text_position_right-bottom {
        top: 63%;
        left: 69%;
    }

    .solutions__graph-text_position_right-center {
        left: 73%;
    }

    .solutions__graph-text_position_right-top {
        top: 32%;
        left: 69%;
    }

    .solutions__graph-text_position_left-top {
        top: 32%;
        left: 5%;
    }

    .solutions__graph-text_position_left-center {
        left: 2%;
    }

    .solutions__graph-text_position_left-bottom {
        top: 62%;
        left: 6%;
    }
}

@media screen and (max-width: 346px) {
    .section_solutions-content {
        height: 309px;
        grid-template-rows: 40px 269px;
    }

    .solutions__graph-text_position_left-top {
        left: 3%;
    }

    .solutions__graph-text_position_left-center {
        left: 0%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 4%;
    }

    .solutions__graph-text_position_bottom-center {
        top: 80%;
        left: 37%;
    }
}

@media screen and (min-width: 319px) and (max-width: 337px) {
    .section_solutions-content {
        height: 260px;
        grid-template-rows: 40px 220px;
    }

     .solutions__graph-text {
        font-size: 7px;
        width: 93px;
    }

    .solutions__graph-text_position_top-center {
        top: 12%;
        left: 36%;
    }

    .solutions__graph-text_position_bottom-center {
        top: 81%;
        left: 36%;
    }

    .solutions__graph-text_position_left-top {
        left: 3%;
    }

    .solutions__graph-text_position_left-center {
        width: 85px;
        left: 3%;
    }

    .solutions__graph-text_position_left-bottom {
        left: 5%;
    }

    .solutions__graph-text_position_right-top {
        left: 68%;
    }

    .solutions__graph-text_position_right-center {
        left: 71%;
    }

    .solutions__graph-text_position_right-bottom {
        left: 67%;
    }
}